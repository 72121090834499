<template>
  <div class="my-ticket-input">
    <label v-if="label" for="input" class="my-ticket-input--label">
      {{ label }}
    </label>
    <input
      :value="modelValue"
      :type="type"
      class="my-ticket-input--input"
      :placeholder="placeholder"
      :maxlength="maxLength"
      name="input"
      @input="$emit('update:modelValue', $event.target.value)"
      @change="validate($event.target.value) & $emit('change', $event)"
    />
    <div v-if="(showError || !valid) && errorMessage !== ''" class="self-start">
      <wegow-error-message :message="$t(errorMessage)" />
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import WegowErrorMessage from "@/components/general/forms/WegowErrorMessage";

const props = defineProps({
  label: {
    type: String,
    default: null
  },
  type: {
    type: String,
    default: "text"
  },
  placeholder: {
    type: String,
    default: null
  },
  modelValue: {
    type: String,
    default: ""
  },
  maxLength: {
    type: String,
    default: ""
  },
  showError: {
    type: Boolean,
    default: false
  },
  validator: {
    type: Object,
    default: undefined
  },
  errorMessage: {
    type: String,
    default: ""
  }
});

const emit = defineEmits(["update:modelValue", "input", "change", "valid"]);

const valid = ref(true);

const validate = value =>
  props.validator
    ? emit("valid", (valid.value = props.validator.validate(value)))
    : true;
</script>

<style lang="scss" scoped>
.my-ticket-input {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
  &--label {
    color: white;
    font-size: 0.875rem;
  }
  &--input {
    width: 100%;
    height: 2rem;
    border-radius: 5px;
    background: #2b2c40;
    padding-left: 1rem;
    padding-right: 1rem;
    color-scheme: dark;
    font-size: 0.75rem;
    &::placeholder {
      color: #7f8295;
      font-size: 0.75rem;
    }
  }
}
</style>
