<template>
  <div class="my-ticket-header" @click="goToLogin">
    <img src="@/assets/images/wegow-conciertos.svg" alt="" />
  </div>
</template>

<script setup>
import { useTicketStore } from "@/stores/modules/ticket";
import { onMounted } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const ticketStore = useTicketStore();

// onMounted(() => {
//   if (!ticketStore.ticket) {
//     router.push({ name: "MyTicketLogin" });
//   }
// });

const goToLogin = () => {
  router.push({ name: "MyTicketLogin" });
};
</script>

<style>
.my-ticket-header {
  display: flex;
  justify-content: center;
  padding: 0.75rem 0;
  height: 4.5rem;
}
</style>
