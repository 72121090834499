import { defineStore } from "pinia";
import PurchaseApi from "@/api/PurchaseApi";
import { useProduct } from "@/composables/useProduct";
import { useEvent } from "@/composables/useEvent";
import { INSURANCE_MANDATORY } from "@/constants.js";
import { validateCreatePurchase } from "@/plugins/errors/validations/purchase-validations";

import { formatCurrency } from "@/plugins/currency";

export const usePurchaseStore = defineStore("purchase", {
  state: () => {
    return {
      actualPurchase: {},
      purchaseData: {},
      bannerAd: {},
      nominativeData: {},
      validator: [],
      newsletter: false,
      termsAccepted: false,
      purchaseExtraData: {},
      accessCodeValid: true,
      accessCode: "",
      maxNumberTicketByCode: -1,
      seatsioData: {},
      referenceParams: {},
      isWhiteLabel: "",
      emailsRepeated: []
    };
  },
  getters: {
    totalPrice: state => {
      let taxes = 0;
      let total = 0;
      let ticketsSelecteds = 0;
      const { totalProductPrice, totalProductTaxes } = useProduct();
      const { details } = useEvent();
      // REMINDER: Para que aparezca el precio total en el PurchaseBanner
      if (state.actualPurchase.selectedTickets?.length > 0) {
        state.actualPurchase.selectedTickets.forEach(element => {
          ticketsSelecteds += element.selecteds;
          taxes =
            taxes +
            element.selecteds *
              (!element.commission
                ? element.customer_commission
                : Number(element.commission));
          let ticket_price = Number(element.price);
          if (element.insurance_type === INSURANCE_MANDATORY)
            ticket_price += Number(element.insurance_premium);
          total = total + ticket_price * element.selecteds;
        });
      }
      taxes += totalProductTaxes.value || 0;
      total += totalProductPrice.value || 0;
      return {
        ticketsSelecteds: ticketsSelecteds,
        taxes: formatCurrency(taxes, details.value.currency),
        totalNoTaxes: formatCurrency(total, details.value.currency),
        totalWithTaxes: formatCurrency(total + taxes, details.value.currency),
        isFree: total + taxes === 0
      };
    }
  },
  actions: {
    async createPurchase(params) {
      try {
        this.purchaseData = await PurchaseApi.createPurchase(
          params,
          this.referenceParams
        );
      } catch (err) {
        validateCreatePurchase(err);
      }
    },
    async createSeatsioPurchase(params) {
      this.purchaseData = await PurchaseApi.createSeatsioPurchase(
        params,
        this.referenceParams
      );
    },
    async createTransfer(params) {
      this.purchaseData = await PurchaseApi.createTransfer(
        params,
        this.referenceParams
      );
    },
    async getThankAd(params) {
      this.bannerAd = await PurchaseApi.getThankAd(params);
    },
    async getPurchase(params) {
      this.purchaseData = await PurchaseApi.getPurchase(params);
    },
    async checkIfEmailIsUnique(params) {
      try {
        await PurchaseApi.checkIfEmailIsUnique(params);
        return true;
      } catch (error) {
        return false;
      }
    },
    async validateAccessCode(params) {
      try {
        let accessCodeData = await PurchaseApi.validateAccessCode(params);
        if (accessCodeData.code) {
          this.accessCode = accessCodeData.code;
          this.maxNumberTicketByCode = accessCodeData.max_num_tickets || -1;
          this.accessCodeValid = true;
          return null;
        } else {
          this.accessCodeValid = false;
          return "Código de acceso no válido";
        }
      } catch (e) {
        this.accessCodeValid = false;
        return e.data.error ? e.data.error.trim() : e.data.trim();
      }
    },
    setReference(reference) {
      this.referenceParams = reference;
    },
    async editWeswapTicket(params) {
      return await PurchaseApi.editWeswapTicket(params);
    },
    setSeatsioData(data) {
      this.seatsioData = data;
    },
    addTicket(ticket) {
      let quantity = ticket.pair ? 2 : 1;
      if (
        ticket.selecteds === 0 &&
        (ticket.selecteds + (ticket.min_tickets || 1) <= ticket.remaining ||
          ticket.selecteds + (ticket.min_tickets || 1) <= ticket.available)
      ) {
        ticket.selecteds += ticket.min_tickets || 1;
      } else if (
        (ticket.selecteds + quantity <= ticket.remaining ||
          ticket.selecteds + quantity <= ticket.available) &&
        ticket.selecteds + quantity <= ticket.max_tickets
      ) {
        ticket.selecteds += quantity;
      }
    },
    createTicketsSelected() {
      return this.purchaseData.tickets.reduce((accumulator, ticket) => {
        accumulator[ticket.ticket_type] =
          (accumulator[ticket.ticket_type] || 0) + 1;
        return accumulator;
      }, {});
    },
    createNewPurchase() {
      const { tickets } = useEvent();
      let selectedTickets = [];
      for (const ticket of tickets.value) {
        let selecteds = 0;
        if (this.purchaseData.tickets) {
          const numberTicketsSelected = this.createTicketsSelected();
          selecteds = numberTicketsSelected[ticket.id] || 0;
        }
        if (!ticket.hidden) {
          selectedTickets.push({
            ...ticket,
            selecteds: ticket.selecteds || selecteds
          });
        }
      }
      this.actualPurchase = {
        selectedTickets: selectedTickets
      };
    },
    buildProductPurchase(products) {
      const { createProductPurchaseData } = useProduct();
      return products.reduce(
        (acc, product) => {
          const productData = createProductPurchaseData(product);

          if (
            acc.associate_products[product.ticket_id] &&
            acc.associate_products[product.ticket_id].id === productData.id
          ) {
            productData.quantity +=
              acc.associate_products[product.ticket_id].quantity;
            productData.price *= productData.quantity;
          }
          if (product.ticket_required) {
            if (!acc.associate_products[product.ticket_id]) {
              acc.associate_products[product.ticket_id] = [];
            }
            const existingProductIndex = acc.associate_products[
              product.ticket_id
            ].findIndex(prod => prod.id === productData.id);
            if (existingProductIndex !== -1) {
              acc.associate_products[product.ticket_id][
                existingProductIndex
              ].quantity += productData.quantity;
              acc.associate_products[product.ticket_id][
                existingProductIndex
              ].price *=
                acc.associate_products[product.ticket_id][
                  existingProductIndex
                ].quantity;
            } else {
              acc.associate_products[product.ticket_id].push(productData);
            }
            // acc.associate_products[product.ticket_type] = productData;
          } else {
            acc.independent_products.push(productData);
          }
          return acc;
        },
        { associate_products: {}, independent_products: [] }
      );
    },
    async createProductPurchase() {
      const { productsPurchase } = useProduct();
      const reference = this.purchaseData.reference;
      const prodPurchase = this.buildProductPurchase(productsPurchase.value);

      await PurchaseApi.createProductPurchase(reference, prodPurchase);
    }
  }
});
